@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

body {
  background-color: #fff;
}

.home {
  overflow: hidden;
}

.login {
  overflow: hidden;
}
